import {BASE} from "../services/urls";

export const GetUsers = async ({ queryKey }) => {

    const { page, limit } = queryKey[1];

    const options: any = {
        method: 'GET',
        headers: {
            'accept': 'application/json',
            "x-access-token": localStorage.getItem('x-access-token'),
            'lang': localStorage.getItem('i18nextLng'),
        }
    };

    const response = await fetch(`${BASE}/users/?limit=${limit}&page=${page}`, options);
    if (!response.ok) throw new Error(JSON.stringify(response.text()));
    return response.json()
}

export const AddUser = async (data) => {

    const options: any = {
        method: 'POST',
        headers: {
            'content-type': 'application/json',
            'accept': 'application/json',
            'lang': localStorage.getItem('i18nextLng'),
            "Authorization": `Bearer ${data.access_token}`
        },
        body: JSON.stringify({
            "firstname": data.firstname,
            "lastname": data.lastname,
            "abv": data.abv,
            "email": data.email,
            "role_id": data.role.id,
            "file": data.file,
        })
    };

    const response = await fetch(`${process.env.REACT_APP_BASE_API}/users`, options);
    const result = await response.json();

    if (!response.ok) throw new Error(result.message)
}

export const UpdateUser = async (data) => {

    const options: any = {
        method: 'PUT',
        headers: {
            'content-type': 'application/json',
            'accept': 'application/json',
            'lang': localStorage.getItem('i18nextLng'),
            "x-access-token": data.access_token,
        },
        body: JSON.stringify({
            "firstname": data.firstname,
            "lastname": data.lastname,
            "email": data.email,
            "role_id": data.role.id,
            "public_id": data.uid
        })
    };

    const response = await fetch(`${process.env.REACT_APP_BASE_API}/users/update`, options);
    const result = await response.json();

    if (!response.ok) throw new Error(result.message)
}

export const UpdateUserPassword = async (data) => {

    const { access_token, password, uid } = data;

    const options: any = {
        method: 'PATCH',
        headers: {
            'content-type': 'application/json',
            'accept': 'application/json',
            'lang': localStorage.getItem('i18nextLng'),
            "Authorization": `Bearer ${access_token}`
        },
        body: JSON.stringify({ "password": password })
    };

    const response = await fetch(`${process.env.REACT_APP_BASE_API}/users/${uid}/update-password`, options);
    const result = await response.json();

    if (!response.ok) throw new Error(result.message)
}

export const ToggleAccountAccess = async (data: any) => {

    const options: any = {
        method: 'PUT',
        headers: {
            'accept': 'application/json',
            "x-access-token": localStorage.getItem('x-access-token'),
        },
        // body: JSON.stringify({ "is_enabled": Number(data.is_enabled) })
    };

    const response = await fetch(`${BASE}/users/${data.uid}/toggle-account-access?state=${Number(data.is_enabled)}`, options);
    const result = await response.json();

    if (!response.ok) throw new Error(result.message)
}

export const API_IMPERSONATE_USER = async (data: any) => {

    const options: any = {
        method: 'POST',
        headers: {
            'accept': 'application/json',
            "x-access-token": localStorage.getItem('x-access-token'),
        }
    };

    const response = await fetch(`${BASE}/authentications/impersonation?id=${data.id}`, options);
    const result = await response.json();

    localStorage.setItem("x-imperso", result["x-access-token"])

    if (!response.ok) throw new Error(result.message)
}

export const DeleteUser = async (data: any) => {

    const options: any = {
        method: 'DELETE',
        headers: {
            'content-type': 'application/json',
            'accept': 'application/json',
            'lang': localStorage.getItem('i18nextLng'),
            "Authorization": `Bearer ${data.access_token}`
        },
    };

    const response = await fetch(`${process.env.REACT_APP_BASE_API}/users/${data.uid}`, options);
    const result = await response.json();

    if (!response.ok) throw new Error(result.message)
}

export const GetCurrentUser = async ({ queryKey }) => {

    const [_key, { access_token, uid }] = queryKey

    const options: any = {
        method: 'GET',
        key: _key,
        headers: {
            'accept': 'application/json',
            "x-access-token": access_token,
            'lang': localStorage.getItem('i18nextLng')
        }
    };

    if (access_token) {
        const response = await fetch(`${BASE}/users/current_user?limit=1&page=1`, options);

        if (!response.ok) throw new Error(JSON.stringify(response.text()))
        return response.json()
    }
}

export const GetUserRoles = async ({ queryKey }) => {

    const [_key, { access_token }] = queryKey

    const options: any = {
        method: 'GET',
        key: _key,
        headers: {
            'accept': 'application/json',
            "x-access-token": access_token,
            'lang': localStorage.getItem('i18nextLng')
        }
    };

    if (access_token) {
        const response = await fetch(`${BASE}/users/roles?limit=10&page=1`, options);

        if (!response.ok) throw new Error(JSON.stringify(response.text()))

        return response.json()
    }
}

export const SearchUsers = async ({ queryKey }) => {

    const { q, page, limit } = queryKey[1];

    const options: any = {
        method: 'GET',
        headers: {
            'accept': 'application/json',
            'lang': localStorage.getItem('i18nextLng'),
            "x-access-token": localStorage.getItem("x-access-token")
        }
    }

    const response = await fetch(`${BASE}/users/search?param=${q}&limit=${limit||50}&page=${page||1}`, options);
    if (!response.ok) throw new Error(JSON.stringify(response.text()))
    return response.json()
}
