import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Field, Form as FinalForm } from "react-final-form";
import arrayMutators from "final-form-arrays";
import _css from "./UpdateOffersForm.module.scss";
import { Button, Grid, GridItem, Skeleton, Spinner, Switch } from "@patternfly/react-core";
import variants from "sass/colors.module.scss";
import useRequestFetch from "react-requests-fetch";
import { QUERY_OFFERS, QUERY_OFFERS_CATEGORIES } from "services/urls";

interface Props {
    idx: number,
    oid: number,
    updateOffers: React.SetStateAction<any>
}

type OfferType = {
    title: string,
    description: string,
    category_id: number,
    is_individual: boolean
}

const UpdateOffersForm: React.FC<Props> = ({
    idx,
    oid,
    updateOffers
}) => {

    const required = value => (value ? undefined : 'sign-up-form-label.field-required');
    const [offer, setOffer] = useState<OfferType>();
    const [categories, setCategories] = useState<any>([]);
    const [createdOffer, updateOffer] = useRequestFetch();
    const [isChecked, setIsChecked] = useState<boolean>(true);
    const formRef = useRef<any>()
    const accessToken = localStorage.getItem("x-access-token");
    const [submitting, isSubmitting] = useState<boolean>(false);
    const [fetching, setFetching] = useState<boolean>(true);

    const [reqOffersCategories] = useRequestFetch({
        uri: QUERY_OFFERS_CATEGORIES,
        params: {
            page: 1,
            limit: 50
        }
    });
    const [reqOffer] = useRequestFetch({
        uri: QUERY_OFFERS,
        params: {
            page: 1,
            limit: 1,
            id: Number(oid)
        }
    });

    const handleSubmit = (values) => {
        isSubmitting(true)
        values.category_id = Number(values.category_id);
        values["is_individual"] = isChecked
        updateOffer({
            uri: QUERY_OFFERS,
            method: 'PUT',
            body: values,
            params: { id: Number(oid) },
            headers: {
                "x-access-token": accessToken,
                "Content-Type": "application/json"
            }
        })
        // formRef.current.reset()
    }

    useEffect(() => {
        if (reqOffer && reqOffer.statusCode === 200) {
            const result = reqOffer.payload;
            setOffer({
                title: result.title,
                description: result.description,
                is_individual: result.is_individual,
                category_id: result.category_id,
            })
            setIsChecked(result.is_individual)
            setFetching(false)
        }
    }, [reqOffer]);

    useEffect(() => {
        (reqOffersCategories && reqOffersCategories.statusCode === 200) &&
            setCategories(reqOffersCategories.payload)
    }, [reqOffersCategories]);

    useEffect(() => {
        (createdOffer && createdOffer.statusCode === 201) &&
            updateOffers(createdOffer.payload, idx)
        isSubmitting(false)
        // eslint-disable-next-line
    }, [createdOffer])

    return (
        <Fragment>
            <FinalForm
                onSubmit={handleSubmit}
                subscription={{
                    submitting: true,
                }}
                initialValues={{
                    title: offer && offer.title,
                    description: offer && offer.description,
                    is_individual: offer && offer.is_individual,
                    category_id: offer && offer.category_id,
                }}
                mutators={{
                    // potentially other mutators could be merged here
                    ...arrayMutators
                }}
                validate={values => {
                    const errors = {};
                    if (!values.title) {
                        errors["title"] = "Required";
                    }
                    if (!values.description) {
                        errors["description"] = "Required";
                    }
                    if (!values.category_id) {
                        errors["description"] = "Required";
                    }
                    return errors;
                }}
            >
                {({ handleSubmit }) => (
                    <form ref={formRef} onSubmit={event => {
                        handleSubmit(event);
                    }} className={_css.container}>
                        <Grid hasGutter>
                            <GridItem>
                                <Grid hasGutter className={_css.form_components}>
                                    <GridItem lg={12}>
                                        {fetching && <Skeleton screenreaderText="Loading contents" />}
                                        {!fetching && <Field
                                            name="title"
                                            validate={required}
                                            subscription={{
                                                value: true,
                                                active: true,
                                                error: true,
                                                touched: true
                                            }}
                                        >
                                            {({
                                                input,
                                                meta
                                            }) => (
                                                <label htmlFor="title">
                                                    <input
                                                        className={[
                                                            _css.input,
                                                            (meta.error && meta.touched) && variants.error_input
                                                        ].join(" ")}
                                                        type="text"
                                                        id="title"
                                                        onBlur={input.onBlur}
                                                        onChange={input.onChange}
                                                        name={input.name}
                                                        placeholder={"Titre du Chronic"}
                                                        defaultValue={offer && offer.title}
                                                    />
                                                </label>
                                            )}
                                        </Field>}
                                    </GridItem>
                                    <GridItem lg={12}>
                                        {fetching && <div style={{ height: '200px' }}>
                                            <Skeleton height="100%" screenreaderText="Loading contents" />
                                        </div>}
                                        {!fetching && <Field
                                            name="description"
                                            validate={required}
                                            subscription={{
                                                value: true,
                                                active: true,
                                                error: true,
                                                touched: true
                                            }}
                                        >
                                            {({
                                                input,
                                                meta
                                            }) => (
                                                <label htmlFor="description">
                                                    <textarea
                                                        className={[
                                                            _css.text_area,
                                                            (meta.error && meta.touched) && variants.error_input
                                                        ].join(" ")}
                                                        id="description"
                                                        onBlur={input.onBlur}
                                                        onChange={input.onChange}
                                                        name={input.name}
                                                        placeholder={"Description de l'offre"}
                                                        defaultValue={offer && offer.description}
                                                    />
                                                </label>
                                            )}
                                        </Field>}
                                    </GridItem>
                                </Grid>
                            </GridItem>
                            <GridItem>
                                {fetching && <Skeleton screenreaderText="Loading contents" />}
                                {!fetching && <Field
                                    name="category_id"
                                    validate={required}
                                    subscription={{
                                        value: true,
                                        active: true,
                                        error: true,
                                        touched: true
                                    }}
                                >
                                    {({
                                        input,
                                        meta,
                                    }) => (
                                        <select
                                            defaultValue={(categories && categories.statusCode === 200) ?
                                                categories[0]["id"] : "N"}
                                            className={[
                                                _css.input,
                                                (meta.error && meta.touched) && variants.error_input
                                            ].join(" ")}
                                            onChange={input.onChange}
                                            onBlur={input.onBlur}
                                            name={input.name}
                                        >
                                            <option value="N" disabled>
                                                Sélectionnez la catégorie préférée.
                                            </option>
                                            {categories.map(category =>
                                                <option
                                                    className={(offer && (offer.category_id === category.id)) ?
                                                        _css.active_category : _css.options}
                                                    key={category.id}
                                                    value={category.id}
                                                >
                                                    {category.name}
                                                </option>)}
                                        </select>
                                    )}
                                </Field>}
                            </GridItem>
                            <GridItem>
                                {fetching && <Skeleton width="30%" screenreaderText="Loading contents" />}
                                {!fetching &&
                                    <Switch
                                        id="individual-switch"
                                        label="L'offre est pour un seul individu."
                                        labelOff="L'offre est collective."
                                        isChecked={isChecked}
                                        onChange={() => setIsChecked(!isChecked)}
                                    />}
                            </GridItem>
                            {!fetching && <GridItem>
                                {submitting &&
                                    <Button isDisabled={submitting} type={"submit"}>
                                        <Spinner size={"sm"} />
                                    </Button>}
                                {!submitting && <Button type={"submit"}>Mettre à jour</Button>}
                            </GridItem>}
                        </Grid>
                    </form>
                )}
            </FinalForm>
        </Fragment>
    )
}

export default UpdateOffersForm;
